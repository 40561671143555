/* 
.icon-links a
.icon-links a .icon
*/
.icon-links a {
  align-items: center;
  background-color: transparent;
  border: 2px solid var(--font-color);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  margin: 15px;
  width: 50px;
}
.icon-links a .icon {
  color: var(--font-color);
  display: inline-block;
  font-size: 20px;
}
/* iPad Pro */
@media screen and (max-width: 1024px) {
}
/* iPad Mini */
@media screen and (max-width: 768px) {
  .icon-links a {
    align-items: center;
    background-color: transparent;
    border: 2px solid var(--font-color);
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: center;
    margin: 10px;
    width: 40px;
  }
}
/* iPhone 14 Pro Max */
@media screen and (max-width: 430px) {
}
