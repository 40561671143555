/* 
.work-section
.work-section .work-section-content .project-section
.work-section work-section-content h1
.work-section .work-section-content p
.work-section .work-section-content p a
 */
.work-section {
  display: block;
  max-width: 90%;
  margin: 50px auto;
  padding: 50px 0;
}
.work-section .work-section-content .project-section {
  width: 33.3%;
  display: block;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
  padding: 10px 10px;
  float: none;
}
.work-section .work-section-content h1 {
  color: var(--font-color);
  font-size: var(--section-header-font-size);
  margin: var(--main-margin) auto;
  text-align: center;
  word-wrap: nowrap;
}
.work-section .work-section-content p {
  color: var(--font-color);
  font-size: var(--paragraph-font-size);
  margin: var(--main-margin) auto;
  text-align: center;
  word-wrap: nowrap;
}
.work-section .work-section-content p a {
  color: #0066cd;
  text-decoration: none;
}
/* iPad Pro */
@media screen and (max-width: 1024px) {
  .work-section .work-section-content .project-section {
    display: block;
    margin-bottom: 20px;
    width: 50%;
  }
}
/* iPad Mini */
@media screen and (max-width: 768px) {
  .work-section .work-section-content .project-section {
    display: block;
    margin-bottom: 20px;
    width: 100%;
  }
  .work-section-content h1 {
    font-size: 24px;
  }
  .work-section-content p {
    font-size: 18px;
  }
}
/* iPhone 14 Pro Max */
@media screen and (max-width: 430px) {
}
