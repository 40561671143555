/* 
.landing-section
.landing-section::before
.landing-section .landing-section-content
.landing-section .landing-section-content h1
.landing-section .landing-section-content p
.landing-section .landing-section-content .landing-buttons button
.landing-section .landing-section-content .scroll-down-animation
@keyframes fade-move-down
 */
.landing-section {
  align-items: center;
  background-image: url("./assets/images/KWRHTNRM.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  height: 100vh;
  justify-content: center;
  position: relative;
}
.landing-section::before {
  background-color: rgba(0, 0, 0, 0.75);
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.landing-section .landing-section-content {
  display: block;
  margin: 0 auto;
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 2;
}
.landing-section .landing-section-content h1 {
  color: var(--bg-color);
  display: block;
  font-size: 48px;
  font-weight: normal;
  margin: 24px;
  text-align: center;
}
.landing-section .landing-section-content p {
  color: var(--bg-color);
  display: block;
  font-size: 24px;
  font-weight: normal;
  margin: 24px;
}
.landing-section .landing-section-content .landing-buttons button {
  background-color: transparent;
  border: 2px solid var(--bg-color);
  border-radius: 50px;
  color: var(--bg-color);
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  height: 45px;
  margin: 15px 20px;
  text-decoration: none;
  transition: 0.5s all;
  width: 140px;
}
.landing-section .landing-section-content .landing-buttons button:hover,
.landing-section .landing-section-content .landing-buttons button:hover {
  background: var(--bg-color);
  color: var(--font-color);
  cursor: pointer;
}
.landing-section .landing-section-content .scroll-down-animation {
  animation: fade-move-down 3s cubic-bezier(0.19, 1, 0.22, 1) infinite;
  border-bottom: 4px solid var(--bg-color);
  border-right: 4px solid var(--bg-color);
  display: block;
  font-size: var(--paragraph-font-size);
  height: 13px;
  margin: 0 auto;
  text-align: center;
  text-decoration: none;
  text-shadow: 0;
  width: 13px;
  z-index: 0;
}
@keyframes fade-move-down {
  0% {
    opacity: 0;
    transform: translate(0, 0) rotate(45deg);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(0, 50px) rotate(45deg);
  }
}
/* iPad Pro */
@media screen and (max-width: 1024px) {
  .landing-button {
    font-size: 16px;
    height: 35px;
    padding: 0 15px;
  }
}
/* iPad Mini */
@media screen and (max-width: 768px) {
  .landing-section .landing-section-content h1 {
    font-size: var(--section-header-font-size);
  }
  .landing-section .landing-section-content p {
    font-size: var(--paragraph-font-size);
  }
}
/* iPhone 14 Pro Max */
@media screen and (max-width: 430px) {
}
