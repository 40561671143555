/* 
.projects-section
.projects-section:after
 */
.projects-section {
  margin: 0 -5px;
}
.projects-section:after {
  clear: both;
  content: "";
  display: table;
}
