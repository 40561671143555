/* 
.navigation-bar
.navigation-bar-name a
.navigation-bar-icons a
 */
.navigation-bar {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  height: 100px;
  justify-content: space-between;
  margin: 0;
  overflow: hidden;
  padding: 40px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
.navigation-bar-name a {
  color: var(--bg-color);
  cursor: pointer;
  display: inline-block;
  margin: 15px 20px;
  text-decoration: none;
  transition: 0.5s all;
}
.navigation-bar-icons a {
  border-radius: 50px;
  color: var(--bg-color);
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  margin: 15px 20px;
  text-decoration: none;
  transition: 0.5s all;
}
/* iPad Pro */
@media screen and (max-width: 1024px) {
  .navigation-bar {
    height: 75px;
    padding: 25px;
  }
  .navigation-bar-icon {
    font-size: 16px;
  }
}
/* iPad Mini */
@media screen and (max-width: 768px) {
}
/* iPhone 14 Pro Max */
@media screen and (max-width: 430px) {
}
