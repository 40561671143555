/* 
.project-section
.project-section .project-section-content
.project-section .project-section-content img
.project-section .project-section-content .project-overlay
.project-section .project-section-content .project-overlay h2
.project-section .project-section-content .project-overlay .project-icons a
*/
.project-section {
  float: left;
  padding: 10px 10px;
  width: 33.3%;
}
.project-section .project-section-content {
  display: flex;
  position: relative;
}
.project-section .project-section-content img {
  border-radius: 20px;
  box-shadow: 0 0 10px 0 var(--font-color);
  width: 100%;
}
.project-section .project-section-content .project-overlay {
  align-items: center;
  background-color: #000000e6;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 0.3s ease;
  width: 100%;
}
.project-section .project-section-content:hover .project-overlay {
  opacity: 1;
}
.project-section .project-section-content .project-overlay h2 {
  color: var(--bg-color);
  margin-bottom: 10px;
}
.project-section .project-section-content .project-overlay .project-icons a {
  border: none;
  color: var(--bg-color);
  cursor: pointer;
  display: inline-block;
  font-size: var(--section-header-font-size);
  outline: none;
  padding: 10px 20px;
}
/* iPad Pro */
@media screen and (max-width: 1024px) {
  .project-section {
    display: block;
    margin-bottom: 20px;
    width: 50%;
  }
}
/* iPad Mini */
@media screen and (max-width: 768px) {
  .project-section {
    display: block;
    margin-bottom: 20px;
    width: 100%;
  }
}
/* iPhone 14 Pro Max */
@media screen and (max-width: 430px) {
}
