/* 
.about-section
.about-section-content
.about-section .about-section-content img
.about-section .about-section-content .about-text h1
.about-section .about-section-content .about-text p
 */
.about-section {
  align-items: center;
  background-color: var(--secondary-bg-color);
  display: flex;
  justify-content: center;
  margin: 50px 0;
  padding: 50px 0;
}
.about-section .about-section-content {
  display: block;
  margin: 0 auto;
  max-width: 50%;
  text-align: center;
}
.about-section .about-section-content img {
  border: 2px solid var(--font-color);
  border-radius: 50%;
  box-shadow: 0 0 10px 0 var(--font-color);
  display: block;
  height: var(--image-main-size);
  margin: var(--main-margin) auto;
  text-align: center;
  width: var(--image-main-size);
}
.about-section .about-section-content .about-text h1 {
  color: var(--font-color);
  font-size: var(--section-header-font-size);
  font-weight: bold;
  margin: var(--main-margin) auto;
  text-align: center;
  word-wrap: nowrap;
}
.about-section .about-section-content .about-text p {
  color: var(--font-color);
  font-size: var(--paragraph-font-size);
  line-height: 40px;
  margin: var(--main-margin) auto;
  text-align: center;
  word-wrap: nowrap;
}
/* iPad Pro */
@media screen and (max-width: 1024px) {
}
/* iPad Mini */
@media screen and (max-width: 768px) {
  .about-section .about-section-content {
    max-width: 90%;
  }
  .about-section .about-section-content img {
    height: 250px;
    width: 250px;
  }
  .about-section .about-section-content .about-text h1 {
    font-size: 24px;
  }
  .about-section .about-section-content .about-text p {
    font-size: 18px;
  }
}
/* iPhone 14 Pro Max */
@media screen and (max-width: 430px) {
}
