/* 
.footer-section
.footer-section .footer-section-content
.footer-section .footer-section-content img
.footer-section .footer-section-content .footer-text
.footer-section .footer-section-content .footer-text h1
.footer-section .footer-section-content .footer-text .icon-links
.footer-section .footer-section-content .footer-text a
.footer-section .footer-section-content .footer-text p
.footer-section .footer-section-content .footer-text a 
 */
.footer-section {
  align-items: center;
  background-color: var(--secondary-bg-color);
  display: flex;
  justify-content: center;
  margin-top: 50px;
  padding-top: 50px;
  width: 100vw;
}
.footer-section .footer-section-content {
  display: block;
  margin: 0 auto;
  max-width: 50%;
}
.footer-section .footer-section-content img {
  border-radius: 0;
  display: block;
  height: var(--image-main-size);
  margin: 0 auto 10px;
  width: var(--image-main-size);
}
.footer-section .footer-section-content .footer-text {
  display: block;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}
.footer-section .footer-section-content .footer-text h1 {
  color: var(--font-color);
  font-size: var(--section-header-font-size);
}
.footer-section .footer-section-content .footer-text .icon-links {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 20px auto;
}
.footer-section .footer-section-content .footer-text a,
.footer-section .footer-section-content .footer-text p {
  color: var(--font-color);
  margin: 10px auto;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
}
.footer-section .footer-section-content .footer-text a {
  margin: 0 10px;
}
.footer-section .footer-section-content .footer-text a:hover {
  text-decoration: underline;
}
/* iPad Pro */
@media screen and (max-width: 1024px) {
}
/* iPad Mini */
@media screen and (max-width: 768px) {
  .footer-section .footer-section-content {
    max-width: 100%;
  }
  .footer-section .footer-section-content .footer-text h1 {
    display: block;
    font-size: 24px;
    margin: 0 auto;
    text-align: center;
    width: 75%;
  }
}
/* iPhone 14 Pro Max */
@media screen and (max-width: 430px) {
}
