:root {
  --bg-color: #fff;
  --font-color: #000;
  --image-main-size: 300px;
  --main-margin: 30px;
  --paragraph-font-size: 20px;
  --section-header-font-size: 32px;
}
body {
  align-items: center;
  background-color: var(--bg-color);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
}
* {
  -webkit-user-select: none;
  box-sizing: border-box;
  font-family: "Avenir";
  margin: 0;
  padding: 0;
  user-select: none;
}
